import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledCloseButton = styled.a`
  --size: 40px;
  margin-left: 2em;
  padding: 8px;
  color: var(--accent);
  width: var(--size);
  height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-weight: bold;
    font-size: 25px;
  }
`

import { CSSProperties } from 'react'

interface CloseButtonProps {
  onClick: () => void
  style?: CSSProperties | undefined
  className?: string
  backTo: string
}

const CloseButton = ({
  onClick,
  style,
  className,
  backTo,
}: CloseButtonProps) => (
  <StyledCloseButton
    className={className}
    onClick={onClick}
    href={`#${backTo}`}
    style={style}
  >
    <span>&#10005;</span>
  </StyledCloseButton>
)

export default CloseButton
