import styled from 'styled-components'
import Portal from './portal'

interface BackdropProps {
  show: boolean
  close?: () => void
}

const StyledBackdrop = styled('div')<BackdropProps>`
  position: ${props => (props.show ? 'fixed' : 'none')};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: var(--z-backdrop);
`

const Backdrop: React.FC<BackdropProps> = ({ show, close }) =>
  show && (
    <Portal>
      <StyledBackdrop show={show} onClick={close} />
    </Portal>
  )

export default Backdrop
