import { initial } from 'lodash-es'
import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

type PortalProps = {
  children: React.ReactNode
}

const Portal: React.FC<PortalProps> = ({ children }) => {
  const initialEl =
    typeof document !== 'undefined' ? document.createElement('div') : null
  const elRef = useRef<HTMLDivElement | null>(initialEl)
  const portalRoot =
    typeof document !== 'undefined' ? document.getElementById('portal') : null

  useEffect(() => {
    if (portalRoot && elRef.current) {
      portalRoot.appendChild(elRef.current)
    }

    return () => {
      if (portalRoot && elRef.current) {
        portalRoot.removeChild(elRef.current)
      }
    }
  }, [])

  return elRef.current ? ReactDOM.createPortal(children, elRef.current) : null
}

export default Portal
