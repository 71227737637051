import { createContext, useReducer, useContext, Dispatch } from 'react'
import stateReducer from './reducer'
import { actionTypes, Action } from './actions'

interface LayoutState {
  scrollLock: boolean
}

const initialState: LayoutState = {
  scrollLock: false,
}

// The defaultValue argument is only used when a component does not have a matching Provider above it in the tree.
// https://reactjs.org/docs/context.html#reactcreatecontext
const LayoutStateContext = createContext<LayoutState | undefined>(undefined)
const LayoutDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined
)

const LayoutStateProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(stateReducer, initialState)
  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  )
}

const useLayoutState = (): LayoutState => {
  const context = useContext(LayoutStateContext)
  if (context === undefined) {
    throw new Error('useLayoutState must be used within a LayoutStateProvider')
  }
  return context
}

const useLayoutDispatch = (): Dispatch<Action> => {
  const context = useContext(LayoutDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useLayoutDispatch must be used within a LayoutStateProvider'
    )
  }
  return context
}

export { LayoutStateProvider, useLayoutState, useLayoutDispatch, actionTypes }
